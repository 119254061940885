import React from 'react'

const Home = () => {
  return (
    <div className='d-flex' style={{height:'80vh',alignItems:'center',justifyContent:'center'}}>
      <p className='h1' style={{color: 'rgb(180, 180, 180)'}}>Recruiter App By ACDL Limited</p>
    </div>
  )
}

export default Home