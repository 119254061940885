import React, { useEffect, useState } from "react";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";

const Transfer = () => {
  const [ settdata] = useState([]);
  const [requests, setrequests] = useState();
  const [alljobs, setalljobs] = useState([]);
  const [allusers, setallusers] = useState([]);
  const jobref = React.createRef();
  const [selecteduser, setselecteduser] = useState([]);
  const candidateref = React.createRef();

  const [fl, setfl] = useState(false);
  const [sl, setsl] = useState(false);
  const [ setbtnloading] = useState(false);

  const [data, setdata] = useState({
    request: [],
    mailusers: [],
    ScheduleDateAndTime: "",
  });

  const user = JSON.parse(sessionStorage.getItem("user"));

  const fetchtdata = () => {
    axios.post("/getschedulcalls", { user: user }).then(
      (response) => {
        if (response.data.status === 200) {
          settdata(response.data.data);
        }
      },
      (error) => {}
    );
  };

  const fetchassignments = () => {
    setfl(true);
    axios.post("/getallJobs", { user: user }).then(
      (response) => {
        if (response.data.status === 200) {
          //  console.log(response.data.data)
          setalljobs(response.data.data);

          setfl(false);
        }
      },
      (error) => {}
    );
  };

  const fetchusers = () => {
    axios.post("/getusers").then(
      (response) => {
        if (response.data.status === 200) {
          setallusers(response.data.data);
        }
      },
      (error) => {}
    );
  };

  const setvalues = (v) => {
    document.getElementById("savebtn").disabled = v;
    if (v) {
      document.getElementById("spinner").style.display = "inline-block";
    } else {
      document.getElementById("spinner").style.display = "none";
    }
  };

  const submitform = (e, users) => {
    e.preventDefault();
    if (data.request.length === 0) {
        alert("Select Acceptance and Users");
    } else {
      setvalues(true);
        axios.post("/updateschedule", { data:data, user: selecteduser })
            .then((response) => {
                if (response.data.status === 200) {
                    alert(response.data.message);
                    jobref.current.resetSelectedValues();
                    candidateref.current.resetSelectedValues();
                    document.getElementById("form").reset();
                    fetchtdata();
                } else if (response.data.status === 303) {
                    alert(response.data.message);
                    document.getElementById("savebtn").disabled = false;
                } else {
                    alert("Failed due to unknown reasons");
                    setbtnloading(false);
                    document.getElementById("savebtn").disabled = false;
                }
                setvalues(false);
            })
            .catch((error) => {
                console.error("Error submitting form:", error);
                alert("Failed due to unknown reasons");
                setvalues(false);
                setbtnloading(false);
                document.getElementById("savebtn").disabled = false;
            });
    }
};


  const fetchspecificrequests = (job) => {
    setsl(true);
    axios.post("/getjobtransfer", { job: job, user: user }).then(
      (response) => {
        if (response.data.status === 200) {
          setrequests(response.data.data);
          setsl(false);
        }
      },
      (error) => {}
    );
  };

  const jobselect = (item) => {
    // setdata(ps => { return { ...ps, job:item[0] } })
    fetchspecificrequests(item[0]);
  };
  const jobremove = (item) => {
    // setdata(ps => { return { ...ps, job: item[0] } })
  };
  const requestselect = (item) => {
    setdata(ps => { return { ...ps, request: item } })
  };
  const requestremove = (item) => {
    setdata(ps => { return { ...ps, request: item } })
  };
  const userselect = (item) => {
    setselecteduser(item[0]);
    console.log("zahid Function",item[0]);
    setdata((ps) => {
      return { ...ps, users: item[0] };
    });
  };
  const userremove = (item) => {
    setdata((ps) => {
      return { ...ps, users: item };
    });
  };

  useEffect(() => {
    fetchtdata();
    fetchassignments();
    fetchusers();
  }, []);

  return (
    <div className="mx-auto my-5 container-fluid">
       <p className="h3 w-100 text-center">Transfer Job</p>
      <form className="row g-3" id="form">
        <div className="col-md-4" id="first">
          <label htmlFor="validationDefault01" className="form-label">
            Job
          </label>
          <Multiselect
            loading={fl}
            ref={jobref}
            options={alljobs}
            displayValue="Title"
            id="validationDefault01"
            selectionLimit={1}
            onSelect={jobselect}
            onRemove={jobremove}
          />
        </div>
        <div className="col-md-4" id="second">
          <label htmlFor="validationDefaultUsername" className="form-label">
            Select Candidate
          </label>
          <Multiselect
            loading={sl}
            ref={candidateref}
            options={requests}
            displayValue="CandidateName"
            id="validationDefault02"
            selectionLimit={1}
            onSelect={requestselect}
            onRemove={requestremove}
          />
        </div>
        <div className="col-md-4" id="user">
          <label htmlFor="validationDefaultUsername" className="form-label">
            Users
          </label>
          <Multiselect
            ref={candidateref}
            options={allusers}
            displayValue="UserName"
            id="validationDefault02"
            onSelect={userselect}
            onRemove={userremove}
          />
        </div>
        <div className="col-12">
          <button
            className="btn btn-success"
            id="savebtn"
            type="button"
            onClick={(e) => {
              submitform(e);
            }}
          >
            {<span>Transfer</span>}
          </button>
          <span id="spinner" style={{ display: "none" }}>
            <span
              className="spinner-border spinner-border-sm text-success mx-3"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="text-success">Saving...</span>
          </span>
        </div>
      </form>
    </div>
  );
};

export default Transfer;
