import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ScheduleList = () => {

  const [schedule, setSchedule] = useState([]);

  const fetchData = () => {
    axios.post('/getschedulcallsList')
      .then((response) => {
        if (response.data.status === 200) {
            setSchedule(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handlesearch = (e) => {
    const searchTerm = e.target.value;
    console.log(searchTerm)
    const result = schedule.filter((item) => item.CandidateName.includes(searchTerm));
    console.log(result);
    if (searchTerm.length > 0) {
      setSchedule(result);
    } else {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="mt-5">
    <p className="h3 w-100 text-center">Calls Scheduled</p>
    <p className="w-100 text-center">Click any Row to Re-Schedule</p>
    <div className="cor-3">
      <input type="text" className="form-control w-25 mx-auto text-center" placeholder="Search Candidate Name" onBlur={e => handlesearch(e)} />
    </div>
    {/* <p className="w-100 text-center">Click to update Record</p> */}
    <div className="table-responsive my-5 rounded-3" style={{ height: '300px', overflow: 'auto' }}>
      <table className="table table-secondary table-hover">
        <thead className="" style={{ position: 'sticky', top: 0 }}>
          <tr>
            <th>Sr</th>
            <th>Candidate Name</th>
            <th>Scheduled TimeAndDate</th>
            <th>Job Title</th>
            <th>Person</th>
            <th>Company</th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((item, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{item.CandidateName}</td>
              <td>{item.ScheduleDateAndTime}</td>
              <td>{item.Title}</td>
              <td>{item.Person}</td>
              <td>{item.Company}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default ScheduleList;
